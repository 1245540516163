import api from "../api";
import axios from "axios";

let { VERIFICATION_API } = api;
let baseUrl = VERIFICATION_API;

export const getExchangeRate = async () => {
  let response = await axios.get(`${baseUrl}/v1/foreign_exchange`, {
    headers: {
      accept: "application/json",
    },
  });
  return response;
};
